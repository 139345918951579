<template>
<div>
    <v-row class="mb-3">
        <v-col cols="4" xl="4" lg="4" md="4" sm="4" class="px-1">
            <div class="pa-3 light-blue lighten-4 rounded">
                <div class="bold f-12">１</div>
                <span class=" f-08-xxs f-07-xxxs">施工箇所<br>の選択</span>
            </div>
        </v-col>
            <!-- <v-col cols="4" xl="4" lg="4" md="4" sm="4" class="px-1">
            <div class="pa-3 light-blue rounded" :class="{'darken-3 f-white':Check==2,'lighten-4':Check!=2}">
                <div class="bold f-12">２</div>
                <span class=" f-08-xxs f-07-xxxs">希望日<br>の選択</span>
            </div>
        </v-col> -->
        <v-col cols="4" xl="4" lg="4" md="4" sm="4" class="px-1">
            <div class="pa-3 light-blue rounded" :class="{'darken-3 f-white':Check==2,'lighten-4':Check!=2}">
                <div class="bold f-12">２</div>
                <span class=" f-08-xxs f-07-xxxs"><span v-if="Info.type==0">施工物件</span><span v-else>お客様</span><br>情報入力</span>
            </div>
        </v-col>
          <v-col cols="4" xl="4" lg="4" md="4" sm="4" class="px-1">
            <div class="pa-3 light-blue rounded" :class="{'darken-3 f-white':Check==3,'lighten-4':Check!=3}">
                <div class="bold f-12">３</div>
                <span class=" f-08-xxs f-07-xxxs">申込内容<br>の確認</span>
            </div>
        </v-col>
    </v-row>
</div>
</template>

<script>
export default {
    props: {
        Check: Number
    },
    computed: {
        Info() {
            return this.$store.getters['user/info']
        },
    }
}
</script>
