<template>
<div>
    <v-row class="text-left">
        <v-col cols="12">
                <label for="card_number" class="f-08">カード番号</label>
                <div id="card_number" class="border rounded px-4 py-2"></div>
        </v-col>
        <v-col cols="6">
                <label for="card_expiry" class="f-08">有効期限</label>
                <div id="card_expiry" class="border rounded px-4 py-2"></div>
        </v-col>
        <v-col cols="6">
                <label for="card_cvc" class="f-08">セキュリティコード</label>
                <div id="card_cvc" class="border rounded px-4 py-2"></div>
        </v-col>
    </v-row>
            <div id="card_error" role="alert" class="f-red mt-6" style="white-space: nowrap;"></div>
</div>
</template>

<script>
import axios from "axios"

export default {

    data() {
        return {
            /* global Stripe */
            stripe: "",
            card: null,
            token: "",
            elementStyles: {
                base: {
                    fontSize: '18px',
                },
                invalid: {
                    color: '#eb1c26',
                }
            },
            elementClasses: {
                focus: 'is-focused',
                empty: 'is-empty',
                invalid: 'is-invalid'
            }
        }
    },

    async mounted() {
        const response = await axios.post("/stripe/get")
        let stripeKey = response.data.stripeKey
        this.stripe = Stripe(stripeKey)

        const elements = this.stripe.elements();

        // カード番号
        const cardNumber = elements.create('cardNumber', {
            style: this.elementStyles,
            classes: this.elementClasses,
        });
        cardNumber.mount('#card_number');

        this.card = cardNumber

        // カードの有効期限
        const cardExpiry = elements.create('cardExpiry', {
            style: this.elementStyles,
            classes: this.elementClasses,
        });
        cardExpiry.mount('#card_expiry');

        // カードのCVC入力
        const cardCvc = elements.create('cardCvc', {
            style: this.elementStyles,
            classes: this.elementClasses,
        });
        cardCvc.mount('#card_cvc');

        let errors = []

        cardNumber.addEventListener('change', function (event) {
            if (event.error) {
                errors[0] = event.error.message;
            } else {
                errors[0] = null;
            }
            if ((errors.filter(v => v))[0]) {
                document.getElementById('card_error').textContent = (errors.filter(v => v))[0]
            } else {
                document.getElementById('card_error').textContent = ""
            }
        });
        cardExpiry.addEventListener('change', function (event) {
            if (event.error) {
                errors[1] = event.error.message;
            } else {
                errors[1] = null;
            }
            if ((errors.filter(v => v))[0]) {
                document.getElementById('card_error').textContent = (errors.filter(v => v))[0]
            } else {
                document.getElementById('card_error').textContent = ""
            }
        });
        cardCvc.addEventListener('change', function (event) {
            if (event.error) {
                errors[3] = event.error.message;
            } else {
                errors[3] = null;
            }
            if ((errors.filter(v => v))[0]) {
                document.getElementById('card_error').textContent = (errors.filter(v => v))[0]
            } else {
                document.getElementById('card_error').textContent = ""
            }
        });
    },

    methods: {
        async cardCheck() {

            const self = this;
            self.show_result = false;
            await this.stripe.createToken(this.card).then(result => {
                if (!result.error) {
                    self.token = result.token.id;
                }
            });
            if (!this.token) {
                return false
            }
            return true
        },
    }

}
</script>

