<template>
<div class="mt-10  mb-10 h-100vh">
    <v-container class="comfirm_box relative-center">
        <Flow :Check="check" />
        <div class="text-left">
            <v-btn text color="indigo accent-4" @click="back">＜【2.物件情報入力】に戻る</v-btn>
        </div>
        <h1 class="f-20 mb-4 mt-6 f-18-xxs">
            お申込内容のご確認
        </h1>
        <v-card class="pa-6 mb-6">
            <h2 class=" f-bgray mb-6 f-14">
                施工箇所
                <hr class="relative-center title_hr" />
            </h2>
            <div v-for="step,i in steps" :key="i">
                <v-row class="f-10" v-if="prices[i]>0">
                    <v-col cols="5" xl="6" lg="6" md="6" sm="6" class="text-left">
                        <div class="bold pr-2">
                            <span v-if="steps[i]==2">フローリング</span>
                            <span v-if="steps[i]==3">和室（畳）</span>
                            <span v-if="steps[i]==5">廊下</span>
                            <span v-if="steps[i]==6">{{types[cars[i]].name}}</span>
                            <span v-if="steps[i]==7">トイレ</span>
                        </div>
                    </v-col>
                    <v-col cols="3" xl="3" lg="3" md="3" sm="3" class="text-left">
                        <div>
                            {{Number(squares[i]).toLocaleString()}}
                            <span v-if="steps[i]==2||steps[i]==3||steps[i]==5||steps[i]==7">m<sup>2</sup>
                                <span v-if="steps[i]==3">({{zyous[i]}})</span>
                                <br>×&nbsp;
                                {{Number(amounts[i]).toLocaleString()}}&nbsp;<small>箇所</small>
                            </span>
                            <!-- <span v-if="steps[i]==3">畳</span> -->
                            <span v-if="steps[i]==6">&nbsp;<small>台</small></span>
                        </div>
                        <div class="clear" />
                    </v-col>
                    <v-col cols="4" xl="3" lg="3" md="3" sm="3" class="text-right">
                        ￥&nbsp;{{prices[i].toLocaleString()}}
                    </v-col>
                </v-row>
            </div>

            <v-row class="f-10 f-red" v-if="campaign">
                <v-col cols="5" xl="6" lg="6" md="6" sm="6" class="text-left">
                    <div class="bold pr-2">
                        {{target}}
                    </div>
                </v-col>
                <v-col cols="3" xl="3" lg="3" md="3" sm="3" class="text-left">
                    <div>
                        1
                        <span v-if="target!='トイレ'">台</span>
                        <span v-if="target=='トイレ'">m<sup>2</sup></span>
                    </div>
                    <div class="clear" />
                </v-col>
                <v-col cols="4" xl="3" lg="3" md="3" sm="3" class="text-right">
                    -&nbsp;￥&nbsp;{{discount.toLocaleString()}}
                </v-col>
            </v-row>

            <div class="text-right mt-3">
                <v-btn color="#E64A19" outlined @click="dialog=true" v-show="!campaign&&discount&&Info.campaign">キャンペーンを利用する</v-btn>
                <div class=" bold mt-3">
                    <div><span class="f-12 f-10-xxs pr-2">計</span>
                        <span class="f-22 pr-1 f-orange f-16-xxs">{{point.toLocaleString()}}</span><span class="f-12 f-10-xxs">箇所</span>
                    </div>
                    <div><span class="f-12 f-10-xxs pr-2">総平米数(車除く)</span>
                        <span class="f-22 pr-1 f-orange f-16-xxs">{{allSquares.toLocaleString()}}</span><span class="f-12 f-10-xxs">m<sup>2</sup></span>
                    </div>
                    <div class="mt-6">
                        <span class="f-brown pr-2 f-14 f-12-xxs">仮見積金額計</span><br class="dis-xxs"><span class="f-orange f-26 f-20-xxs">{{((prices.reduce((sum, element) => sum + element, 0))-(discount*campaign)).toLocaleString()}}</span><span class="f-12 f-10-xxs">円<small class="pl-1 f-09-xxs">(税込)</small></span>
                    </div>
                </div>
            </div>
            <hr class="mx-2 mb-4 mt-2" />
            <h2 class=" f-bgray mb-2 f-13">
                施工物件･お客様情報の入力
                <hr class="relative-center title_hr" />
            </h2>
            <div class="text-left mb-4">
                <div class="f-brown f-11">お問合せ内容</div>
                <div class="bold f-bgray f-12 mb-2">{{subject}}
                    <span v-if="subject=='その他'">（{{etc}}）</span>
                </div>
                <div class="f-brown f-11">ご住所</div>
                <div class="bold f-bgray f-12" v-if="zip">〒{{zip}}</div>
                <div class="bold f-bgray f-12 mb-2">{{address}}</div>


                <div class="f-brown f-11">お名前（または法人名）</div>
                <div class="bold f-bgray f-12 mb-2">{{name}}</div>

                <div class="f-brown f-11">メールアドレス</div>
                <div class="bold f-bgray f-12 mb-2">{{email}}</div>

                <div class="f-brown f-11">お電話番号</div>
                <div class="bold f-bgray f-12 mb-2">{{tel}}</div>
                <div v-if="contact">
                    <div class="f-brown f-11">その他</div>
                    <div class="bold f-bgray f-12 mb-2" style="white-space: pre-wrap;">{{contact}}</div>
                </div>
            </div>

            <!-- <div class="rounded day_border bold pa-3">
                <div class="f-brown f-14 f-12-xxs">施工ご希望日時</div>
                <div class="f-orange f-20 f-14-xxs f-10-xxxs">{{moment(day).format("yyyy年M月D日")}}&nbsp;&nbsp;{{time}}</div>
                <div v-if="station>0">
                    <div class="f-brown f-14 mt-6">施工場所</div>
                    <div class="f-orange f-14">
                        {{Station[Station.findIndex(({id}) => id === station)].name}}
                    </div>
                    <div>
                        〒{{Station[Station.findIndex(({id}) => id === station)].zip}}&nbsp;{{Station[Station.findIndex(({id}) => id === station)].address}}{{Station[Station.findIndex(({id}) => id === station)].address2}}
                    </div>
                    <div>
                        （<a :href="'https://www.google.com/maps/place/〒'+
                                Station[Station.findIndex(({id}) => id === station)].zip+'+'+Station[Station.findIndex(({id}) => id === station)].address+Station[Station.findIndex(({id}) => id === station)].address2
                                " target="_blank">地図を開く</a>）
                    </div>
                </div>
            </div> -->
        </v-card>
        <v-card class="pa-6 mb-6 disno" v-show="((prices.reduce((sum, element) => sum + element, 0))-(discount*campaign))>0">
            <h2 class=" f-bgray mb-6 f-14">
                <span v-if="payments.length>1">お支払方法の選択</span>
                <span v-else>お支払方法</span>
                <hr class="relative-center title_hr" />
            </h2>
            <v-form ref="form">
                <v-select v-model="payment" :rules="requredRules" :items="payments" label="お支払方法の選択" outlined v-if="payments.length>1" />
                <div v-else class="bold f-orange f-12">{{payment}}</div>
            </v-form>
            <v-expand-transition>
                <div v-show="payment=='クレジットカード決済'">
                    <hr class="my-6" />
                    <h2 class="f-brown">カード情報の入力</h2>
                    <CardForm ref="cardForm" />
                </div>
            </v-expand-transition>
        </v-card>

        <v-btn color="#E64A19" x-large dark block class="f-12 mt-10" @click="submit()">
            <span v-show="loading==0">お問合せ内容を送信する</span>
            <v-progress-circular v-show="loading==1" indeterminate color="white"></v-progress-circular>
        </v-btn>
    </v-container>
    <v-dialog width="600" v-model="dialog">
        <v-card class="pa-6">
            <h2 class="f-orange f-12 f-10-xxxs mb-4">トイレ１室&nbsp;または&nbsp;お車１台&nbsp;<br class="dis-xxs">無料キャンペーン</h2>
            <div><span class="bold mb-2">キャンペーン対象：</span>{{target}}</div>
            <div class="bold f-red pr-2">【値引価格】<span class="f-22">￥{{discount.toLocaleString()}}</span></div>
            <v-btn color="#E64A19" large dark class="f-12 mt-5" @click="campaign=1,dialog=false">適用する</v-btn>
        </v-card>
    </v-dialog>
    <Loading v-show="loading!=0" />
</div>
</template>

<script>
import moment from "moment"
import Vue from "vue"
Vue.prototype.moment = moment

import form from '@/mixins/form'
import axios from "axios"

import Loading from "@/components/loading.vue"
import CardForm from "@/components/cardForm.vue"
import Flow from '@/components/flow.vue'

export default {
    mixins: [form],
    data() {
        return {
            dialog: false,
            loading: false,
            show: false,
            check: 3,
        }
    },
    components: {
        CardForm,
        Loading,
        Flow
    },
    computed: {
        Station() {
            return this.$store.getters['user/station']
        },
    },
    created() {
        for (let key in this.$route.params) {
            this[key] = this.$route.params[key]
        }
        let infoPayment = this.Info.payment.split(',');
        let payments = []
        for (let i = 0; i < infoPayment.length; i++) {
            if (JSON.parse(infoPayment[i])) {
                payments.push(this.payments[i].name)
            }
        }
        this.payments = payments

        if (this.payments.length == 1) {
            this.payment = this.payments[0]
        }

        if (this.steps.indexOf(6) != -1 || this.steps.indexOf(7) != -1) {
            let discount = 0
            let target = ""
            let carNum = 0
            for (let i = 0; i < this.steps.length; i++) {

                if (this.steps[i] == 7 && discount < this.prices[i]) {
                    target = "トイレ"
                    discount = this.prices[i]
                }

                if (this.steps[i] == 6) {
                    carNum += this.squares[i] - 1
                    if (discount < this.types[this.cars[i]].price) {
                        switch (this.types[this.cars[i]].id) {
                            case 0:
                                target = "軽自動車"
                                break
                            case 1:
                                target = "普通車"
                                break
                            case 2:
                                target = "ワンボックスカー"
                                break
                        }
                        discount = this.types[this.cars[i]].price
                    }
                }
            }
            this.target = target
            this.discount = discount
            this.carNum = carNum
        }
    },
    methods: {
        back() {
            let params = this.$route.params

            this.$router.push({
                name: 'estate',
                params: params,
            })
        },
        async submit() {

            if (((this.prices.reduce((sum, element) => sum + element, 0)) - this.discount * this.campaign) < 100 && this.payment == 'クレジットカード決済') {
                alert("カード決済は100円以上からご利用できます")
                return false
            }

            if (((this.prices.reduce((sum, element) => sum + element, 0)) - this.discount * this.campaign) <= 0) {
                this.payment = "お支払いはありません"
            }

            if (!this.$refs.form.validate()) {
                return false
            }
            if (this.payment == 'クレジットカード決済') {
                if (document.getElementById("cardErrors") && document.getElementById("cardErrors").innerText) {
                    alert("クレジットカード情報に誤りがあります")
                    return false
                }

                const card_result = await this.$refs.cardForm.cardCheck()
                if (!card_result) {
                    this.loading = 0;
                    return false
                }
            }
            this.loading = 1

            let datas = {}

            for (let key in this.$route.params) {
                switch (key) {
                    case "steps":
                    case "squares":
                    case "prices":
                    case "cars":
                    case "zyous":
                    case "amounts":
                        datas[key] = JSON.stringify(this.$route.params[key])
                        break
                    case "day":
                        datas[key] = moment(this.day).format("yyyy-MM-DD")
                        break
                    default:
                        datas[key] = this.$route.params[key]

                }
            }

            datas["types"] = JSON.stringify(this.types.map(item => item["name"]))

            datas["payment"] = this.payment

            if (this.campaign) {
                datas["target"] = this.target
                datas["discount"] = this.discount
            }

            let response = await axios.post("/order/payment", datas)

            if (!response.data.ok) {
                alert(response.data.error)
                this.loading = false
                return false
            }

            if (this.payment == 'クレジットカード決済') {

                const data = {
                    email: this.$route.params.email,
                    name: this.$route.params.name,
                    amount: (this.prices.reduce((sum, element) => sum + element, 0)) - this.discount * this.campaign,
                    token: this.$refs.cardForm.token,
                }
                const response = await axios.post('/stripe/submit', data)

                if (!response.data.ok) {
                    alert(response.data.error)
                    this.loading = false
                    return false
                }
            }

            response = await axios.post("/order/mail", datas)

            if (response.data.ok) {
                this.$router.push("thankyou")
            } else {
                alert("エラーが発生しました")
            }

            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/comfirm.scss";
@import "@/assets/sass/order.scss";
</style>
